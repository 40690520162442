var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        dialogClass: "translate-modal",
        zIndex: 1000,
        maskClosable: false,
        dialogStyle: _vm.isAddTerm ? { left: "200px", top: "130px" } : {},
        width: 715,
        visible: _vm.visible,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "closeIcon" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "closeIcon",
        },
        [_c("a-icon", { attrs: { type: "close" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "modal-title",
          attrs: { slot: "title" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "title",
        },
        [_vm._v(_vm._s(_vm.isAddTerm ? "预览" : "编辑术语表"))]
      ),
      !_vm.isEdit
        ? _c(
            "div",
            { style: _vm.backCss, on: { mouseover: _vm.topCenterMouseover } },
            [
              _c("a-icon", {
                class:
                  _vm.curRow && _vm.curRow.hasLast
                    ? "term-prev change-page"
                    : "term-prev",
                style:
                  _vm.curRow && _vm.curRow.hasLast
                    ? _vm.curRow.term.termType == "人物"
                      ? "top:250px;"
                      : "top:150px;"
                    : "color:rgba(0,0,0,0.25);",
                attrs: { type: "left-circle" },
                on: { click: _vm.prev },
              }),
              _c("a-icon", {
                class:
                  _vm.curRow && _vm.curRow.hasNext
                    ? "term-next change-page"
                    : "term-next",
                style:
                  _vm.curRow && _vm.curRow.hasNext
                    ? _vm.curRow.term.termType == "人物"
                      ? "top:250px;"
                      : "top:150px;"
                    : "color:rgba(0,0,0,0.25);",
                attrs: { type: "right-circle" },
                on: { click: _vm.next },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isEdit
        ? _c(
            "div",
            {
              staticClass: "term-form",
              on: { mouseover: _vm.topCenterMouseover },
            },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "dataForm",
                    staticStyle: { "padding-bottom": "70px" },
                    attrs: {
                      layout: "horizontal",
                      model: _vm.formData,
                      rules: _vm.rules,
                    },
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "分类", prop: "termTypeName" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择分类" },
                          on: { change: _vm.templateChange },
                          model: {
                            value: _vm.formData.termTypeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "termTypeName", $$v)
                            },
                            expression: "formData.termTypeName",
                          },
                        },
                        _vm._l(_vm.templateNameList, function (i) {
                          return _c(
                            "a-select-option",
                            { key: i.id, attrs: { value: i.name } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(i.name) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formType == "人物"
                    ? [
                        _c(
                          "a-form-model-item",
                          {
                            staticClass: "upload-item",
                            attrs: { label: "头像", prop: "subjectImgUrl" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "a-upload",
                                  {
                                    staticClass:
                                      "avatar-uploader avatar-uploader-first",
                                    attrs: {
                                      name: "avatar",
                                      "list-type": "picture-card",
                                      "show-upload-list": false,
                                      "before-upload": (file) =>
                                        _vm.beforeCroUpload(file, 0),
                                    },
                                  },
                                  [
                                    _vm.formData.subjectImgUrl[0]
                                      ? _c("a-icon", {
                                          attrs: { type: "close-circle" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clearUrl(0)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.formData.subjectImgUrl[0]
                                      ? _c(
                                          "div",
                                          { staticClass: "avatar-show" },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "90px",
                                                height: "90px",
                                              },
                                              attrs: {
                                                src: _vm.formData
                                                  .subjectImgUrl[0],
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c("a-icon", {
                                              attrs: {
                                                type: _vm.avatarLoading
                                                  ? "loading"
                                                  : "plus",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ant-upload-text",
                                              },
                                              [_vm._v("主头像上传")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                                _c("Editor", {
                                  ref: "editor",
                                  staticClass: "img-editor",
                                  staticStyle: {
                                    height: "30px",
                                    overflow: "hidden",
                                    width: "108px",
                                    position: "relative",
                                    top: "-1px",
                                  },
                                  attrs: {
                                    defaultConfig: _vm.editorConfig,
                                    mode: _vm.mode,
                                  },
                                  on: {
                                    onCreated: _vm.onCreated,
                                    customPaste: _vm.customPaste,
                                  },
                                  model: {
                                    value: _vm.copyUrl1,
                                    callback: function ($$v) {
                                      _vm.copyUrl1 = $$v
                                    },
                                    expression: "copyUrl1",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "a-upload",
                                  {
                                    staticClass: "avatar-uploader",
                                    attrs: {
                                      name: "avatar",
                                      "list-type": "picture-card",
                                      "show-upload-list": false,
                                      "before-upload": (file) =>
                                        _vm.beforeCroUpload(file, 1),
                                    },
                                  },
                                  [
                                    _vm.formData.subjectImgUrl[1]
                                      ? _c("a-icon", {
                                          attrs: { type: "close-circle" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clearUrl(1)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.formData.subjectImgUrl[1]
                                      ? _c(
                                          "div",
                                          { staticClass: "avatar-show" },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "90px",
                                                height: "90px",
                                              },
                                              attrs: {
                                                src: _vm.formData
                                                  .subjectImgUrl[1],
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c("a-icon", {
                                              attrs: {
                                                type: _vm.avatarLoading
                                                  ? "loading"
                                                  : "plus",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ant-upload-text",
                                              },
                                              [_vm._v("上传")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                                _c("Editor", {
                                  ref: "editor",
                                  staticClass: "img-editor",
                                  staticStyle: {
                                    height: "30px",
                                    overflow: "hidden",
                                    width: "106px",
                                  },
                                  attrs: {
                                    defaultConfig: _vm.editorConfig2,
                                    mode: _vm.mode,
                                  },
                                  on: {
                                    onCreated: _vm.onCreated2,
                                    customPaste: _vm.customPaste2,
                                  },
                                  model: {
                                    value: _vm.copyUrl2,
                                    callback: function ($$v) {
                                      _vm.copyUrl2 = $$v
                                    },
                                    expression: "copyUrl2",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "a-upload",
                                  {
                                    staticClass: "avatar-uploader",
                                    attrs: {
                                      name: "avatar",
                                      "list-type": "picture-card",
                                      "show-upload-list": false,
                                      "before-upload": (file) =>
                                        _vm.beforeCroUpload(file, 2),
                                    },
                                  },
                                  [
                                    _vm.formData.subjectImgUrl[2]
                                      ? _c("a-icon", {
                                          attrs: { type: "close-circle" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.clearUrl(2)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.formData.subjectImgUrl[2]
                                      ? _c(
                                          "div",
                                          { staticClass: "avatar-show" },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "90px",
                                                height: "90px",
                                              },
                                              attrs: {
                                                src: _vm.formData
                                                  .subjectImgUrl[2],
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c("a-icon", {
                                              attrs: {
                                                type: _vm.avatarLoading
                                                  ? "loading"
                                                  : "plus",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ant-upload-text",
                                              },
                                              [_vm._v("上传")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                                _c("Editor", {
                                  ref: "editor",
                                  staticClass: "img-editor",
                                  staticStyle: {
                                    height: "30px",
                                    overflow: "hidden",
                                    width: "106px",
                                  },
                                  attrs: {
                                    defaultConfig: _vm.editorConfig3,
                                    mode: _vm.mode,
                                  },
                                  on: {
                                    onCreated: _vm.onCreated3,
                                    customPaste: _vm.customPaste3,
                                  },
                                  model: {
                                    value: _vm.copyUrl3,
                                    callback: function ($$v) {
                                      _vm.copyUrl3 = $$v
                                    },
                                    expression: "copyUrl3",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "other-item origin-other-item" },
                          [_vm._v("\n          原文名\n        ")]
                        ),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "姓", prop: "originAll" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入姓" },
                              on: { change: _vm.originNameChange },
                              model: {
                                value: _vm.formData.originFamilyName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "originFamilyName",
                                    $$v
                                  )
                                },
                                expression: "formData.originFamilyName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "名", prop: "originAll" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入名" },
                              on: { change: _vm.originNameChange },
                              model: {
                                value: _vm.formData.originGivenName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "originGivenName", $$v)
                                },
                                expression: "formData.originGivenName",
                              },
                            }),
                            _vm.formData.originName &&
                            _vm.terms.filter(
                              (w) =>
                                _vm.formData.originName.indexOf(w.value) > -1
                            ).length
                              ? _c(
                                  "div",
                                  { staticClass: "check-origin" },
                                  _vm._l(
                                    _vm.terms.filter(
                                      (w) => _vm.formData.originName == w.value
                                    ),
                                    function (word) {
                                      return _c("div", { key: word.id }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              word.value + "→" + word.target
                                            )
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "other-item" }, [
                          _vm._v("\n          译文名\n        "),
                        ]),
                        _c(
                          "a-form-model-item",
                          {
                            staticClass: "phonetic-item",
                            attrs: { label: "姓", prop: "processedName" },
                          },
                          [
                            (_vm.departName.indexOf("韩语部") > -1 &&
                              _vm.isDepart) ||
                            _vm.departName.indexOf("韩语部") == -1
                              ? _c(
                                  "span",
                                  [
                                    _c("a-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入姓" },
                                      model: {
                                        value: _vm.formData.processedFamilyName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "processedFamilyName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.processedFamilyName",
                                      },
                                    }),
                                    _vm.formData.phoneticFamilyNameTextList &&
                                    _vm.formData.phoneticFamilyNameTextList
                                      .length
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.formData.phoneticFamilyNameTextList
                                                    .map((p) => p.value)
                                                    .join("")
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c("a-input", {
                                  attrs: { placeholder: "请输入姓" },
                                  model: {
                                    value: _vm.formData.processedFamilyName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "processedFamilyName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.processedFamilyName",
                                  },
                                }),
                            _vm.departName == "日语部"
                              ? _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: {
                                      loading: _vm.inputLoading,
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPhonetic(
                                          _vm.formData.processedFamilyName,
                                          "processedFamilyName"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("获取注音")]
                                )
                              : _vm._e(),
                            _vm.departName.indexOf("韩语部") > -1 &&
                            _vm.isDepart
                              ? _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.recommendText(
                                          _vm.formData.originName,
                                          "processedFamilyName"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看推荐用字")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticClass: "phonetic-item",
                            attrs: { label: "名", prop: "processedName" },
                          },
                          [
                            (_vm.departName.indexOf("韩语部") > -1 &&
                              _vm.isDepart) ||
                            _vm.departName.indexOf("韩语部") == -1
                              ? _c(
                                  "span",
                                  [
                                    _c("a-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入名" },
                                      model: {
                                        value: _vm.formData.processedGivenName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "processedGivenName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.processedGivenName",
                                      },
                                    }),
                                    _vm.formData.phoneticGivenNameTextList &&
                                    _vm.formData.phoneticGivenNameTextList
                                      .length
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.formData.phoneticGivenNameTextList
                                                    .map((p) => p.value)
                                                    .join("")
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c("a-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: { placeholder: "请输入名" },
                                  model: {
                                    value: _vm.formData.processedGivenName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "processedGivenName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.processedGivenName",
                                  },
                                }),
                            _vm.departName == "日语部"
                              ? _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: {
                                      loading: _vm.inputLoading,
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPhonetic(
                                          _vm.formData.processedGivenName,
                                          "processedGivenName"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("获取注音")]
                                )
                              : _vm._e(),
                            _vm.departName.indexOf("韩语部") > -1 &&
                            _vm.isDepart
                              ? _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.recommendText(
                                          _vm.formData.originName,
                                          "processedGivenName"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看推荐用字")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticClass: "phonetic-item",
                            attrs: {
                              label: "略称（小名）",
                              prop: "originNickname",
                            },
                          },
                          [
                            (_vm.departName.indexOf("韩语部") > -1 &&
                              _vm.isDepart) ||
                            _vm.departName.indexOf("韩语部") == -1
                              ? _c(
                                  "span",
                                  [
                                    _c("a-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入略称" },
                                      model: {
                                        value: _vm.formData.originNickname,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "originNickname",
                                            $$v
                                          )
                                        },
                                        expression: "formData.originNickname",
                                      },
                                    }),
                                    _vm.formData.phoneticNickNameTextList &&
                                    _vm.formData.phoneticNickNameTextList.length
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.formData.phoneticNickNameTextList
                                                    .map((p) => p.value)
                                                    .join("")
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c("a-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: { placeholder: "请输入略称" },
                                  model: {
                                    value: _vm.formData.originNickname,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "originNickname",
                                        $$v
                                      )
                                    },
                                    expression: "formData.originNickname",
                                  },
                                }),
                            _vm.departName == "日语部"
                              ? _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: {
                                      loading: _vm.inputLoading,
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPhonetic(
                                          _vm.formData.originNickname,
                                          "originNickname"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("获取注音")]
                                )
                              : _vm._e(),
                            _vm.departName.indexOf("韩语部") > -1 &&
                            _vm.isDepart
                              ? _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.recommendText(
                                          _vm.formData.originName,
                                          "originNickname"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看推荐用字")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.departName == "日语部"
                          ? _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.checkPermission(
                                      "phoneticStatus:edit"
                                    ) && _vm.isEdit,
                                  label: "注音情况",
                                  prop: "processedName",
                                },
                              },
                              [
                                _c(
                                  "a-radio-group",
                                  {
                                    model: {
                                      value: _vm.formData.phoneticStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "phoneticStatus",
                                          $$v
                                        )
                                      },
                                      expression: "formData.phoneticStatus",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-radio",
                                      {
                                        staticStyle: { height: "30px" },
                                        attrs: { value: 0 },
                                      },
                                      [_vm._v("未注音")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        staticStyle: { height: "30px" },
                                        attrs: { value: 1 },
                                      },
                                      [_vm._v("已注音")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        staticStyle: { height: "30px" },
                                        attrs: { value: 2 },
                                      },
                                      [_vm._v("姓未注音")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        staticStyle: { height: "30px" },
                                        attrs: { value: 3 },
                                      },
                                      [_vm._v("名未注音")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        staticStyle: { height: "30px" },
                                        attrs: { value: 4 },
                                      },
                                      [_vm._v("每次注音")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        staticStyle: { height: "30px" },
                                        attrs: { value: 5 },
                                      },
                                      [_vm._v("不需要注音")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "初次登场章节", prop: "debut" } },
                          [
                            _c("a-input-number", {
                              attrs: {
                                min: 0,
                                placeholder: "请输入初次登场章节",
                              },
                              model: {
                                value: _vm.formData.debut,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "debut", $$v)
                                },
                                expression: "formData.debut",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "性别", prop: "gender" } },
                          [
                            _c(
                              "a-select",
                              {
                                attrs: { placeholder: "请选择性别" },
                                model: {
                                  value: _vm.formData.gender,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "gender", $$v)
                                  },
                                  expression: "formData.gender",
                                },
                              },
                              [
                                _c(
                                  "a-select-option",
                                  { key: 1, attrs: { value: "男" } },
                                  [_vm._v("\n              男\n            ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: 2, attrs: { value: "女" } },
                                  [_vm._v("\n              女\n            ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: 3, attrs: { value: "无性别" } },
                                  [
                                    _vm._v(
                                      "\n              无性别\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: 4, attrs: { value: "待定" } },
                                  [_vm._v("\n              待定\n            ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            attrs: { label: "身份（职业）", prop: "identity" },
                          },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入身份" },
                              model: {
                                value: _vm.formData.identity,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "identity", $$v)
                                },
                                expression: "formData.identity",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "自称" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入自称" },
                              model: {
                                value: _vm.formData.call,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "call", $$v)
                                },
                                expression: "formData.call",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "语气" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入语气" },
                              model: {
                                value: _vm.formData.tone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "tone", $$v)
                                },
                                expression: "formData.tone",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "说明", prop: "description" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "限制五十字以内" },
                              model: {
                                value: _vm.formData.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "description", $$v)
                                },
                                expression: "formData.description",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.formData.termTypeName != "配角" &&
                        _vm.formData.termTypeName != "路人"
                          ? _vm._l(_vm.formData.characters, function (i, idx) {
                              return _c(
                                "a-form-model-item",
                                _vm._b(
                                  {
                                    key: idx + 1 + "xg",
                                    attrs: { label: idx == 0 ? "性格" : "" },
                                  },
                                  "a-form-model-item",
                                  idx === 0
                                    ? _vm.layout
                                    : {
                                        wrapperCol: {
                                          xs: { span: 24, offset: 0 },
                                          sm: { span: 18, offset: 6 },
                                        },
                                      },
                                  false
                                ),
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "a-input-group",
                                        { attrs: { compact: "" } },
                                        [
                                          _c("a-input", {
                                            staticStyle: { width: "32%" },
                                            attrs: {
                                              placeholder: "请输入性格",
                                            },
                                            model: {
                                              value: i.name,
                                              callback: function ($$v) {
                                                _vm.$set(i, "name", $$v)
                                              },
                                              expression: "i.name",
                                            },
                                          }),
                                          _c("a-input", {
                                            staticStyle: { width: "68%" },
                                            attrs: {
                                              placeholder: "请输入性格描述",
                                            },
                                            model: {
                                              value: i.description,
                                              callback: function ($$v) {
                                                _vm.$set(i, "description", $$v)
                                              },
                                              expression: "i.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "end-btns",
                                          staticStyle: { top: "-5px" },
                                        },
                                        [
                                          _c("a-button", {
                                            staticClass: "item-btn",
                                            attrs: {
                                              disabled:
                                                _vm.formData.characters
                                                  .length <= 1,
                                              shape: "circle",
                                              size: "small",
                                              icon: "minus",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delxg(
                                                  _vm.formData.characters,
                                                  idx
                                                )
                                              },
                                            },
                                          }),
                                          _c("a-button", {
                                            staticClass: "item-btn",
                                            attrs: {
                                              shape: "circle",
                                              size: "small",
                                              icon: "plus",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addxg(
                                                  _vm.formData.characters,
                                                  idx
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            })
                          : _vm._l(_vm.formData.characters, function (i, idx) {
                              return _c(
                                "a-form-model-item",
                                _vm._b(
                                  {
                                    key: idx + 1 + "xg",
                                    attrs: { label: idx == 0 ? "性格" : "" },
                                  },
                                  "a-form-model-item",
                                  idx === 0
                                    ? _vm.layout
                                    : {
                                        wrapperCol: {
                                          xs: { span: 24, offset: 0 },
                                          sm: { span: 18, offset: 6 },
                                        },
                                      },
                                  false
                                ),
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "a-input-group",
                                        { attrs: { compact: "" } },
                                        [
                                          _c("a-input", {
                                            staticStyle: { width: "32%" },
                                            attrs: {
                                              placeholder: "请输入性格",
                                            },
                                            model: {
                                              value: i.name,
                                              callback: function ($$v) {
                                                _vm.$set(i, "name", $$v)
                                              },
                                              expression: "i.name",
                                            },
                                          }),
                                          _c("a-input", {
                                            staticStyle: { width: "68%" },
                                            attrs: {
                                              placeholder: "请输入性格描述",
                                            },
                                            model: {
                                              value: i.description,
                                              callback: function ($$v) {
                                                _vm.$set(i, "description", $$v)
                                              },
                                              expression: "i.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "end-btns",
                                          staticStyle: { top: "-5px" },
                                        },
                                        [
                                          _c("a-button", {
                                            staticClass: "item-btn",
                                            attrs: {
                                              disabled:
                                                _vm.formData.characters
                                                  .length <= 1,
                                              shape: "circle",
                                              size: "small",
                                              icon: "minus",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delxg(
                                                  _vm.formData.characters,
                                                  idx
                                                )
                                              },
                                            },
                                          }),
                                          _c("a-button", {
                                            staticClass: "item-btn",
                                            attrs: {
                                              shape: "circle",
                                              size: "small",
                                              icon: "plus",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addxg(
                                                  _vm.formData.characters,
                                                  idx
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "是否有团体", prop: "tt" } },
                          [
                            _c(
                              "a-radio-group",
                              {
                                on: { change: _vm.teamRadioChange },
                                model: {
                                  value: _vm.formData.hasTeam,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "hasTeam", $$v)
                                  },
                                  expression: "formData.hasTeam",
                                },
                              },
                              [
                                _c("a-radio", { attrs: { value: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("a-radio", { attrs: { value: 2 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.formData.hasTeam == 1
                          ? _vm._l(_vm.formData.teamList, function (item, idx) {
                              return _c(
                                "a-form-model-item",
                                _vm._b(
                                  {
                                    key: idx + 1 + "tt",
                                    attrs: {
                                      prop: "tt",
                                      label: idx == 0 ? "团体" : "",
                                    },
                                  },
                                  "a-form-model-item",
                                  idx === 0
                                    ? _vm.layout
                                    : {
                                        wrapperCol: {
                                          xs: { span: 24, offset: 0 },
                                          sm: { span: 18, offset: 6 },
                                        },
                                      },
                                  false
                                ),
                                [
                                  _c(
                                    "a-input-group",
                                    { attrs: { compact: "" } },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          staticStyle: { width: "52%" },
                                          attrs: { placeholder: "请选择团体" },
                                          on: {
                                            change: (value) =>
                                              _vm.teamChange(value, item),
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "dropdownRender",
                                                fn: function (menu) {
                                                  return _c(
                                                    "div",
                                                    {},
                                                    [
                                                      _c("v-nodes", {
                                                        attrs: { vnodes: menu },
                                                      }),
                                                      _c("a-divider", {
                                                        staticStyle: {
                                                          margin: "4px 0",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            padding: "4px 8px",
                                                            cursor: "pointer",
                                                          },
                                                          on: {
                                                            mousedown: (e) =>
                                                              e.preventDefault(),
                                                            click: _vm.openTeam,
                                                          },
                                                        },
                                                        [
                                                          _c("a-icon", {
                                                            attrs: {
                                                              type: "plus",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " 新建团体\n                  "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: item.team,
                                            callback: function ($$v) {
                                              _vm.$set(item, "team", $$v)
                                            },
                                            expression: "item.team",
                                          },
                                        },
                                        _vm._l(_vm.teamList, function (i) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: i.id,
                                              attrs: {
                                                disabled:
                                                  _vm.formData.teamList &&
                                                  _vm.formData.teamList.some(
                                                    (t) => t.team == i.id
                                                  ),
                                                value: i.id,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(i.originName) +
                                                  "/" +
                                                  _vm._s(i.processedName) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                      _c("a-input", {
                                        staticStyle: { width: "48%" },
                                        attrs: {
                                          placeholder: "请输入在团体的职责",
                                        },
                                        model: {
                                          value: item.teamDescription,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "teamDescription",
                                              $$v
                                            )
                                          },
                                          expression: "item.teamDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "end-btns",
                                      staticStyle: { top: "-14px" },
                                    },
                                    [
                                      _c("a-button", {
                                        staticClass: "item-btn",
                                        attrs: {
                                          disabled:
                                            _vm.formData.teamList.length <= 1,
                                          shape: "circle",
                                          size: "small",
                                          icon: "minus",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delTeam(
                                              _vm.formData.teamList,
                                              idx
                                            )
                                          },
                                        },
                                      }),
                                      _c("a-button", {
                                        staticClass: "item-btn",
                                        attrs: {
                                          shape: "circle",
                                          size: "small",
                                          icon: "plus",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addTeam(
                                              _vm.formData.teamList,
                                              idx
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "other-item",
                            on: {
                              click: function ($event) {
                                return _vm.openOher("top")
                              },
                            },
                          },
                          [
                            _c("a-icon", {
                              class:
                                _vm.showTop &&
                                _vm.formData.gxList &&
                                _vm.formData.gxList.length
                                  ? "open-oher-item"
                                  : "close-oher-item",
                              attrs: { type: "right" },
                            }),
                            _vm._v("\n          关系\n        "),
                          ],
                          1
                        ),
                        _vm.showTop &&
                        _vm.formData.gxList &&
                        _vm.formData.gxList.length
                          ? _c("div", { staticClass: "gx-list-show" }, [
                              _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v("\n            注:填写单一关系。"),
                                _c("br"),
                                _vm._v(
                                  "\n            例，此术语表是小明的术语表，小红是小明的妹妹，此时添加关系时，人物选择“小红”，关系填入“妹妹”，称呼填入小明对小红的称呼即可。\n          "
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "gx-list" },
                                [
                                  _c(
                                    "a-row",
                                    { staticStyle: { "font-weight": "700" } },
                                    [
                                      _c("a-col", { attrs: { span: 6 } }, [
                                        _vm._v("人物"),
                                      ]),
                                      _c("a-col", { attrs: { span: 5 } }, [
                                        _vm._v("关系"),
                                      ]),
                                      _c("a-col", { attrs: { span: 5 } }, [
                                        _vm._v("敬语/平语"),
                                      ]),
                                      _c("a-col", { attrs: { span: 8 } }, [
                                        _vm._v("称呼\n                "),
                                        !_vm.formData.gxList ||
                                        (_vm.formData.gxList &&
                                          !_vm.formData.gxList.length)
                                          ? _c(
                                              "div",
                                              { staticClass: "end-btns-table" },
                                              [
                                                _c("a-button", {
                                                  staticClass: "item-btn",
                                                  attrs: {
                                                    disabled: true,
                                                    shape: "circle",
                                                    size: "small",
                                                    icon: "minus",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delTeam(
                                                        _vm.formData.gxList,
                                                        _vm.index
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c("a-button", {
                                                  staticClass: "item-btn",
                                                  attrs: {
                                                    shape: "circle",
                                                    size: "small",
                                                    icon: "plus",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addgx(
                                                        _vm.formData.gxList,
                                                        -1
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._l(
                                    _vm.formData.gxList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        { key: index + 1 + "gx" },
                                        [
                                          _c(
                                            "a-row",
                                            { staticClass: "gx-row" },
                                            [
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: { span: 6 },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editGxItem(
                                                        _vm.formData.gxList,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.processedName || ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: { span: 5 },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editGxItem(
                                                        _vm.formData.gxList,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.name || "")
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: { span: 5 },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editGxItem(
                                                        _vm.formData.gxList,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.honorific
                                                        ? item.honorific ==
                                                          "其他"
                                                          ? `其他${
                                                              item.elseInfo
                                                                ? "，" +
                                                                  item.elseInfo
                                                                : ""
                                                            }`
                                                          : item.honorific
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "padding-right": "35px",
                                                    "line-height": "20px",
                                                    "padding-top": "15px",
                                                    "padding-bottom": "15px",
                                                  },
                                                  attrs: { span: 8 },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editGxItem(
                                                        _vm.formData.gxList,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.call) +
                                                      "\n                  "
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "end-btns-table",
                                                    },
                                                    [
                                                      _c("a-button", {
                                                        staticClass: "item-btn",
                                                        attrs: {
                                                          shape: "circle",
                                                          size: "small",
                                                          icon: "minus",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.delTeam(
                                                              _vm.formData
                                                                .gxList,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("a-button", {
                                                        staticClass: "item-btn",
                                                        attrs: {
                                                          shape: "circle",
                                                          size: "small",
                                                          icon: "plus",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.addgx(
                                                              _vm.formData
                                                                .gxList,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "other-item",
                            on: {
                              click: function ($event) {
                                return _vm.openOher("bottom")
                              },
                            },
                          },
                          [
                            _c("a-icon", {
                              class: _vm.showBottom
                                ? "open-oher-item"
                                : "close-oher-item",
                              attrs: { type: "right" },
                            }),
                            _vm._v("\n          其他选填内容\n        "),
                          ],
                          1
                        ),
                        _vm.showBottom
                          ? _c(
                              "a-form-model-item",
                              { attrs: { label: "职称", prop: "jobTitle" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.formData.jobTitle,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "jobTitle", $$v)
                                    },
                                    expression: "formData.jobTitle",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showBottom
                          ? _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "公司名称",
                                  prop: "companyName",
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.formData.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "companyName", $$v)
                                    },
                                    expression: "formData.companyName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showBottom
                          ? _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "爱称（褒义）",
                                  prop: "goodNickname",
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.formData.goodNickname,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "goodNickname",
                                        $$v
                                      )
                                    },
                                    expression: "formData.goodNickname",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showBottom
                          ? _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "外号（贬义）",
                                  prop: "badNickname",
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.formData.badNickname,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "badNickname", $$v)
                                    },
                                    expression: "formData.badNickname",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showBottom
                          ? _c(
                              "a-form-model-item",
                              { attrs: { label: "邮箱", prop: "email" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.formData.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "email", $$v)
                                    },
                                    expression: "formData.email",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showBottom
                          ? _c(
                              "a-form-model-item",
                              {
                                attrs: { label: "游戏ID", prop: "gameAccount" },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.formData.gameAccount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "gameAccount", $$v)
                                    },
                                    expression: "formData.gameAccount",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showBottom
                          ? _c(
                              "a-form-model-item",
                              { attrs: { label: "手机号", prop: "mobile" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.formData.mobile,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "mobile", $$v)
                                    },
                                    expression: "formData.mobile",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showBottom
                          ? _c(
                              "a-form-model-item",
                              { attrs: { label: "学校", prop: "school" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.formData.school,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "school", $$v)
                                    },
                                    expression: "formData.school",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showBottom
                          ? _c(
                              "a-form-model-item",
                              { attrs: { label: "班级", prop: "clazz" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.formData.clazz,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "clazz", $$v)
                                    },
                                    expression: "formData.clazz",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showBottom
                          ? _c(
                              "a-form-model-item",
                              { attrs: { label: "武器", prop: "weapon" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.formData.weapon,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "weapon", $$v)
                                    },
                                    expression: "formData.weapon",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.formType == "团体"
                    ? [
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "原文名", prop: "originName" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入原文名" },
                              model: {
                                value: _vm.formData.originName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "originName", $$v)
                                },
                                expression: "formData.originName",
                              },
                            }),
                            _vm.formData.originName &&
                            _vm.terms.filter(
                              (w) =>
                                _vm.formData.originName.indexOf(w.value) > -1
                            ).length
                              ? _c(
                                  "div",
                                  { staticClass: "check-origin" },
                                  _vm._l(
                                    _vm.terms.filter(
                                      (w) => _vm.formData.originName == w.value
                                    ),
                                    function (word) {
                                      return _c("div", { key: word.id }, [
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.openTerm(word)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                word.value + "→" + word.target
                                              )
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticClass: "phonetic-item",
                            attrs: {
                              label: "译文名（全名）",
                              prop: "processedName",
                            },
                          },
                          [
                            _c(
                              "span",
                              [
                                _c("a-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: { placeholder: "请输入译文名" },
                                  model: {
                                    value: _vm.formData.processedName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "processedName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.processedName",
                                  },
                                }),
                                _vm.formData.phoneticTextList &&
                                _vm.formData.phoneticTextList.length
                                  ? _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.formData.phoneticTextList
                                                .map((p) => p.value)
                                                .join("")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.departName == "日语部"
                              ? _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: {
                                      loading: _vm.inputLoading,
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPhonetic(
                                          _vm.formData.processedName,
                                          "processedName"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("获取注音")]
                                )
                              : _vm._e(),
                            _vm.departName.indexOf("韩语部") > -1
                              ? _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.recommendText(
                                          _vm.formData.originName,
                                          "originNickname"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看推荐用字")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.departName.indexOf("日语部") > -1
                          ? _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.checkPermission(
                                      "phoneticStatus:edit"
                                    ) && _vm.isEdit,
                                  label: "注音情况",
                                  prop: "processedName",
                                },
                              },
                              [
                                _c(
                                  "a-radio-group",
                                  {
                                    model: {
                                      value: _vm.formData.phoneticStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "phoneticStatus",
                                          $$v
                                        )
                                      },
                                      expression: "formData.phoneticStatus",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-radio",
                                      {
                                        staticStyle: { height: "30px" },
                                        attrs: { value: 0 },
                                      },
                                      [_vm._v("未注音")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        staticStyle: { height: "30px" },
                                        attrs: { value: 1 },
                                      },
                                      [_vm._v("已注音")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        staticStyle: { height: "30px" },
                                        attrs: { value: 4 },
                                      },
                                      [_vm._v("每次注音")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        staticStyle: { height: "30px" },
                                        attrs: { value: 5 },
                                      },
                                      [_vm._v("不需要注音")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "初次登场章节", prop: "debut" } },
                          [
                            _c("a-input-number", {
                              attrs: {
                                min: 0,
                                placeholder: "请输入初次登场章节",
                              },
                              model: {
                                value: _vm.formData.debut,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "debut", $$v)
                                },
                                expression: "formData.debut",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "说明", prop: "description" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "限制五十字以内" },
                              model: {
                                value: _vm.formData.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "description", $$v)
                                },
                                expression: "formData.description",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c("terms-cropper-model", {
                ref: "termsCropperModel",
                on: { upImg: _vm.upImg },
              }),
              _c("terms-team-modal", {
                ref: "termsTeamModel",
                attrs: {
                  departName: _vm.departName,
                  productionId: _vm.taskDetail.productionId,
                  parent: _vm.taskDetail,
                },
                on: { afterSave: _vm.getTeamList },
              }),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "term-form",
              on: { mouseover: _vm.topCenterMouseover },
            },
            [
              _vm.curRow.term && _vm.curRow.term.termType == "人物"
                ? [
                    _c(
                      "info-item",
                      {
                        staticStyle: { "padding-bottom": "12px" },
                        attrs: { labelWidth: _vm.labelWidth, label: "头像" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              position: "relative",
                            },
                          },
                          [
                            _vm.curRow.term && _vm.curRow.term.subjectImgUrl[0]
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      border: "2px solid green",
                                      "border-radius": "50%",
                                      padding: "5px",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "avatar-show" }, [
                                      _c("img", {
                                        staticStyle: {
                                          width: "90px",
                                          height: "90px",
                                        },
                                        attrs: {
                                          src: _vm.curRow.term.subjectImgUrl[0],
                                        },
                                      }),
                                    ]),
                                  ]
                                )
                              : _c("div", { staticClass: "avatar-show" }, [
                                  _c("img", {
                                    staticStyle: {
                                      width: "90px",
                                      height: "90px",
                                    },
                                    attrs: {
                                      src: require("./../../../assets/tx.png"),
                                      alt: "",
                                    },
                                  }),
                                ]),
                            _vm.curRow.term && _vm.curRow.term.subjectImgUrl[1]
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "avatar-show",
                                    staticStyle: { "margin-left": "20px" },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "90px",
                                        height: "90px",
                                      },
                                      attrs: {
                                        src: _vm.curRow.term.subjectImgUrl[1],
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "avatar-show",
                                    staticStyle: { "margin-left": "20px" },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "90px",
                                        height: "90px",
                                      },
                                      attrs: {
                                        src: require("./../../../assets/tx.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                            _vm.curRow.term && _vm.curRow.term.subjectImgUrl[2]
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "avatar-show",
                                    staticStyle: { "margin-left": "20px" },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "90px",
                                        height: "90px",
                                      },
                                      attrs: {
                                        src: _vm.curRow.term.subjectImgUrl[2],
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "avatar-show",
                                    staticStyle: { "margin-left": "20px" },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "90px",
                                        height: "90px",
                                      },
                                      attrs: {
                                        src: require("./../../../assets/tx.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                          ]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "other-item" }, [
                      _vm._v("\n        原文名\n      "),
                    ]),
                    _c(
                      "info-item",
                      { attrs: { labelWidth: _vm.labelWidth, label: "姓" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.originFamilyName) +
                            "\n      "
                        ),
                      ]
                    ),
                    _c(
                      "info-item",
                      { attrs: { labelWidth: _vm.labelWidth, label: "名" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.originGivenName) +
                            "\n      "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "other-item" }, [
                      _vm._v("\n        译文名\n      "),
                    ]),
                    _c(
                      "info-item",
                      { attrs: { labelWidth: _vm.labelWidth, label: "姓" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.processedFamilyName) +
                            "\n        "
                        ),
                        _vm.curRow.term.phoneticFamilyNameTextList &&
                        _vm.curRow.term.phoneticFamilyNameTextList.length
                          ? _c(
                              "span",
                              { staticStyle: { "margin-left": "12px" } },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.curRow.term.phoneticFamilyNameTextList
                                        .map((p) => p.value)
                                        .join("")
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "info-item",
                      { attrs: { labelWidth: _vm.labelWidth, label: "名" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.processedGivenName) +
                            "\n        "
                        ),
                        _vm.curRow.term.phoneticGivenNameTextList &&
                        _vm.curRow.term.phoneticGivenNameTextList.length
                          ? _c(
                              "span",
                              { staticStyle: { "margin-left": "12px" } },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.curRow.term.phoneticGivenNameTextList
                                        .map((p) => p.value)
                                        .join("")
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "info-item",
                      {
                        attrs: {
                          labelWidth: _vm.labelWidth,
                          label: "略称（小名）",
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.originNickname) +
                            "\n        "
                        ),
                        _vm.curRow.term.phoneticNickNameTextList &&
                        _vm.curRow.term.phoneticNickNameTextList.length
                          ? _c(
                              "span",
                              { staticStyle: { "margin-left": "12px" } },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.curRow.term.phoneticNickNameTextList
                                        .map((p) => p.value)
                                        .join("")
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm.departName == "日语部"
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "注音情况",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.curRow.term.phoneticStatus == 0
                                    ? "未注音"
                                    : _vm.curRow.term.phoneticStatus == 1
                                    ? "已注音"
                                    : _vm.curRow.term.phoneticStatus == 2
                                    ? "姓未注音"
                                    : _vm.curRow.term.phoneticStatus == 3
                                    ? "名未注音"
                                    : _vm.curRow.term.phoneticStatus == 4
                                    ? "每次注音"
                                    : _vm.curRow.term.phoneticStatus == 5
                                    ? "不需要注音"
                                    : ""
                                ) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "info-item",
                      {
                        attrs: {
                          labelWidth: _vm.labelWidth,
                          label: "初次登场章节",
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.debut) +
                            "\n      "
                        ),
                      ]
                    ),
                    _c(
                      "info-item",
                      { attrs: { labelWidth: _vm.labelWidth, label: "性别" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.gender) +
                            "\n      "
                        ),
                      ]
                    ),
                    _c(
                      "info-item",
                      {
                        attrs: {
                          labelWidth: _vm.labelWidth,
                          label: "身份（职业）",
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.identity) +
                            "\n      "
                        ),
                      ]
                    ),
                    _c(
                      "info-item",
                      { attrs: { labelWidth: _vm.labelWidth, label: "自称" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.call) +
                            "\n      "
                        ),
                      ]
                    ),
                    _c(
                      "info-item",
                      { attrs: { labelWidth: _vm.labelWidth, label: "语气" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.tone) +
                            "\n      "
                        ),
                      ]
                    ),
                    _c(
                      "info-item",
                      { attrs: { labelWidth: _vm.labelWidth, label: "性格" } },
                      _vm._l(_vm.curRow.term.characters, function (i, idx) {
                        return _c(
                          "div",
                          {
                            key: idx + 1 + "xgShow",
                            staticStyle: { display: "flex" },
                          },
                          [
                            _c("div", { staticStyle: { width: "32%" } }, [
                              _vm._v(_vm._s(i.name)),
                            ]),
                            _c("div", { staticStyle: { width: "68%" } }, [
                              _vm._v(_vm._s(i.description)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "info-item",
                      { attrs: { labelWidth: _vm.labelWidth, label: "说明" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.description) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm.curRow.relations["团体关系"]
                      ? [
                          _c(
                            "info-item",
                            {
                              attrs: {
                                labelWidth: _vm.labelWidth,
                                label: "团体",
                              },
                            },
                            _vm._l(
                              _vm.curRow.relations["团体关系"],
                              function (i, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: idx + 1 + "ttShow",
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "32%" } },
                                      [_vm._v(_vm._s(i.originName))]
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { width: "68%" } },
                                      [_vm._v(_vm._s(i.fromOtherInfo.call))]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      : _vm._e(),
                    _vm.curRow.relations["人物关系"] &&
                    _vm.curRow.relations["人物关系"] &&
                    _vm.curRow.relations["人物关系"].length
                      ? _c(
                          "div",
                          {
                            staticClass: "other-item",
                            on: {
                              click: function ($event) {
                                return _vm.openOher("curTop")
                              },
                            },
                          },
                          [
                            _c("a-icon", {
                              class: _vm.showCurTop
                                ? "open-oher-item"
                                : "close-oher-item",
                              attrs: { type: "right" },
                            }),
                            _vm._v("\n        关系\n      "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showCurTop &&
                    _vm.curRow.relations["人物关系"] &&
                    _vm.curRow.relations["人物关系"].length
                      ? _c("div", [
                          _c("div"),
                          _c(
                            "div",
                            { staticClass: "gx-list" },
                            [
                              _c(
                                "a-row",
                                { staticStyle: { "font-weight": "700" } },
                                [
                                  _c("a-col", { attrs: { span: 6 } }, [
                                    _vm._v("人物"),
                                  ]),
                                  _c("a-col", { attrs: { span: 5 } }, [
                                    _vm._v("关系"),
                                  ]),
                                  _c("a-col", { attrs: { span: 5 } }, [
                                    _vm._v("敬语/平语"),
                                  ]),
                                  _c("a-col", { attrs: { span: 8 } }, [
                                    _vm._v("称呼"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.curRow.relations["人物关系"],
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index + 1 + "gx",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditTermModal(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "a-row",
                                        { staticClass: "gx-row" },
                                        [
                                          _c(
                                            "a-col",
                                            {
                                              attrs: { span: 6 },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openEditTermModal(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "avatar-show-mini",
                                                attrs: {
                                                  src:
                                                    item.subjectImgUrl ||
                                                    "./../../../assets/tx.png",
                                                  alt: "",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "15px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.processedName || ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a-col",
                                            {
                                              attrs: { span: 5 },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openEditTermModal(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.name || ""))]
                                          ),
                                          item.typeDesc == "left"
                                            ? _c(
                                                "a-col",
                                                {
                                                  attrs: { span: 5 },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openEditTermModal(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.fromOtherInfo &&
                                                        item.fromOtherInfo
                                                          .honorific
                                                        ? item.fromOtherInfo
                                                            .honorific == "其他"
                                                          ? `其他${
                                                              item.elseInfo
                                                                ? "，" +
                                                                  item.elseInfo
                                                                : ""
                                                            }`
                                                          : item.fromOtherInfo
                                                              .honorific
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "a-col",
                                                {
                                                  attrs: { span: 5 },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openEditTermModal(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.otherInfo &&
                                                        item.otherInfo.honorific
                                                        ? item.otherInfo
                                                            .honorific == "其他"
                                                          ? `其他${
                                                              item.elseInfo
                                                                ? "，" +
                                                                  item.elseInfo
                                                                : ""
                                                            }`
                                                          : item.otherInfo
                                                              .honorific
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                          item.typeDesc == "left"
                                            ? _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "line-height": "20px",
                                                    "padding-top": "15px",
                                                    "padding-bottom": "15px",
                                                  },
                                                  attrs: { span: 8 },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openEditTermModal(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.fromOtherInfo &&
                                                        item.fromOtherInfo.call
                                                        ? item.fromOtherInfo
                                                            .call
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "line-height": "20px",
                                                    "padding-top": "15px",
                                                    "padding-bottom": "15px",
                                                  },
                                                  attrs: { span: 8 },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openEditTermModal(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.otherInfo &&
                                                        item.otherInfo.call
                                                        ? item.otherInfo.call
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "other-item",
                        on: {
                          click: function ($event) {
                            return _vm.openOher("curBottom")
                          },
                        },
                      },
                      [
                        _c("a-icon", {
                          class: _vm.showCurBottom
                            ? "open-oher-item"
                            : "close-oher-item",
                          attrs: { type: "right" },
                        }),
                        _vm._v("\n        其他选填内容\n      "),
                      ],
                      1
                    ),
                    _vm.showCurBottom
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "职称",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.curRow.term.jobTitle) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showCurBottom
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "公司名称",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.curRow.term.companyName) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showCurBottom
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "爱称（褒义）",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.curRow.term.goodNickname) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showCurBottom
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "外号（贬义）",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.curRow.term.badNickname) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showCurBottom
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "邮箱",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.curRow.term.email) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showCurBottom
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "游戏ID",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.curRow.term.gameAccount) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showCurBottom
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "手机号",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.curRow.term.mobile) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showCurBottom
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "学校",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.curRow.term.school) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showCurBottom
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "班级",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.curRow.term.clazz) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showCurBottom
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "武器",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.curRow.term.weapon) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : _vm.curRow.term && _vm.curRow.term.termType == "团体"
                ? [
                    _c(
                      "info-item",
                      {
                        attrs: { labelWidth: _vm.labelWidth, label: "原文名" },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.originName) +
                            "\n      "
                        ),
                      ]
                    ),
                    _c(
                      "info-item",
                      {
                        attrs: {
                          labelWidth: _vm.labelWidth,
                          label: "译文名（全名）",
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.processedName) +
                            "\n        "
                        ),
                        _vm.curRow.term.phoneticTextList &&
                        _vm.curRow.term.phoneticTextList.length
                          ? _c(
                              "span",
                              { staticStyle: { "margin-left": "12px" } },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.curRow.term.phoneticTextList
                                        .map((p) => p.value)
                                        .join("")
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm.departName == "日语部"
                      ? _c(
                          "info-item",
                          {
                            attrs: {
                              labelWidth: _vm.labelWidth,
                              label: "注音情况",
                            },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.curRow.term.phoneticStatus == 0
                                    ? "未注音"
                                    : _vm.curRow.term.phoneticStatus == 1
                                    ? "已注音"
                                    : _vm.curRow.term.phoneticStatus == 2
                                    ? "姓未注音"
                                    : _vm.curRow.term.phoneticStatus == 3
                                    ? "名未注音"
                                    : _vm.curRow.term.phoneticStatus == 4
                                    ? "每次注音"
                                    : _vm.curRow.term.phoneticStatus == 5
                                    ? "不需要注音"
                                    : ""
                                ) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "info-item",
                      {
                        attrs: {
                          labelWidth: _vm.labelWidth,
                          label: "初次登场章节",
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.debut) +
                            "\n      "
                        ),
                      ]
                    ),
                    _c(
                      "info-item",
                      { attrs: { labelWidth: _vm.labelWidth, label: "说明" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.curRow.term.description) +
                            "\n      "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            {
              staticStyle: { float: "left" },
              style: _vm.backCss,
              on: { mouseover: _vm.topCenterMouseover },
            },
            [
              !_vm.isAddTerm &&
              !_vm.isEdit &&
              _vm.curRow &&
              _vm.curRow.term &&
              _vm.curRow.term.status != "已驳回"
                ? _c(
                    "span",
                    {
                      staticClass: "reject-button reject",
                      staticStyle: { "margin-right": "5px" },
                      on: {
                        click: function ($event) {
                          return _vm.reject(_vm.curRow)
                        },
                      },
                    },
                    [_vm._v("驳回")]
                  )
                : _vm._e(),
              !_vm.isAddTerm &&
              !_vm.isEdit &&
              _vm.curRow &&
              _vm.curRow.term &&
              _vm.curRow.term.status != "已确认"
                ? _c(
                    "span",
                    {
                      staticClass: "reject-button send",
                      on: {
                        click: function ($event) {
                          return _vm.send(_vm.curRow)
                        },
                      },
                    },
                    [_vm._v("确认")]
                  )
                : _vm._e(),
            ]
          ),
          !_vm.isAddTerm && _vm.isEdit
            ? _c(
                "a-button",
                {
                  key: "back",
                  on: { mouseover: _vm.topCenterMouseover, click: _vm.noEdit },
                },
                [_vm._v("\n      取消\n    ")]
              )
            : _vm._e(),
          !_vm.isAddTerm && !_vm.isEdit
            ? _c(
                "a-button",
                {
                  key: "back",
                  on: {
                    mouseover: _vm.topCenterMouseover,
                    click: _vm.handleCancel,
                  },
                },
                [_vm._v("\n      关闭\n    ")]
              )
            : _vm._e(),
          !_vm.isEdit
            ? _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: { mouseover: _vm.topCenterMouseover, click: _vm.edit },
                },
                [_vm._v("\n      编辑\n    ")]
              )
            : _vm._e(),
          !_vm.isAddTerm && _vm.isEdit
            ? _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: {
                    mouseover: _vm.topCenterMouseover,
                    click: _vm.handleOk,
                  },
                },
                [_vm._v("\n      确定\n    ")]
              )
            : _vm._e(),
          _vm.isAddTerm
            ? _c(
                "a-button",
                {
                  key: "back",
                  on: {
                    mouseover: _vm.topCenterMouseover,
                    click: _vm.handleCancel,
                  },
                },
                [_vm._v("\n      关闭\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "驳回理由",
            zIndex: 9000,
            visible: _vm.rejectVisible,
          },
          on: { ok: _vm.handleRejectOk, cancel: _vm.handleRejectCancel },
        },
        [
          _c("a-input", {
            attrs: { placeholder: "请输入驳回理由" },
            model: {
              value: _vm.rejectReason,
              callback: function ($$v) {
                _vm.rejectReason = $$v
              },
              expression: "rejectReason",
            },
          }),
        ],
        1
      ),
      _c("terms-gx-modal", {
        ref: "termsGxModel",
        attrs: { roleList: _vm.roleList },
        on: { saveGx: _vm.saveGx, editGx: _vm.editGx },
      }),
      _c("terms-phonetic-modal", {
        ref: "termsPhoneticModal",
        on: { savePhonetic: _vm.savePhonetic },
      }),
      _c("terms-recommend-modal", {
        ref: "termsRecommendModal",
        attrs: { parent: _vm.taskDetail },
        on: { saveRecommend: _vm.saveRecommend },
      }),
      _c("term-edit-modal", {
        ref: "termEditModal",
        attrs: { taskDetail: _vm.taskDetail, color: _vm.color },
        on: { getTerms: _vm.getTerms },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }